import { Plus } from 'assets/icons/Plus'
import { useTranslation } from 'react-i18next'

export default function BasicPlan() {
  const {t} = useTranslation("common")
  return (
    <>
      <div className='border-b border-[#f4f4f4] p-4 md:p-6 flex justify-between'>
        <div>
          <div className='flex items-center'>
            <span className='font-bold text-xl mr-2'>{t("SUBSCRIPTION.BASIC_PLAN")}</span>
            <div className=' rounded-full border p-2 text-sm font-semibold'> • {t("SUBSCRIPTION.ACTIVE")}</div>
          </div>
          <div className=' text-sm text-[#7c7979]'>{t("SUBSCRIPTION.PLAN_CONTENT")}</div>
          <div className=' mt-6 flex items-center'>
            <img className=' rounded-full border-2 border-white w-10 h-10 object-cover object-center' src="assets/flags/VN.png" alt="" />
            <img className=' rounded-full border-2 border-white w-10 h-10 object-cover object-center -ml-4' src="assets/flags/VN.png" alt="" />
            <img className=' rounded-full border-2 border-white w-10 h-10 object-cover object-center -ml-4' src="assets/flags/VN.png" alt="" />
            <img className=' rounded-full border-2 border-white w-10 h-10 object-cover object-center -ml-4' src="assets/flags/VN.png" alt="" />
            <img className=' rounded-full border-2 border-white w-10 h-10 object-cover object-center -ml-4' src="assets/flags/VN.png" alt="" />
            <img className=' rounded-full border-2 border-white w-10 h-10 object-cover object-center -ml-4' src="assets/flags/VN.png" alt="" />
            <div className=' rounded-full border border-[#8b8a8a] ml-1 w-8 h-8 object-cover object-center flex justify-center items-center'><Plus color='#8b8a8a' /></div>
          </div>
        </div>
        <div className=' flex flex-col justify-between md:justify-around'>
          <div className=' flex items-end'>
            <span className='text-xl md:text-[40px] font-bold'>$20</span> <span className='font-medium text-xs md:text-base py-1 md:py-2'>{t("SUBSCRIPTION.PER_MONTH")}</span>
          </div>
          <div className=' text-right text-sm'>10 of 15 users</div>
        </div>
      </div>
      <div className=' p-6 flex justify-end'>
        <div className=' rounded-xl w-fit  border p-2 text-base font-medium'>{t("SUBSCRIPTION.UPGRADE_PLAN")}</div>
      </div>
    </>
  )
}
