/* eslint-disable react/react-in-jsx-scope */

import { ArrowDown } from "assets/icons/ArrowDown";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {FolderArrowDownIcon, DocumentTextIcon} from '@heroicons/react/20/solid'
import { CheckMark } from "assets/icons/CheckMark";

const datatemp = [
  {
    title: "invoice #007 - Dec 2022",
    billing_date: "11/12/2022",
    amount: "USD 1000$",
    plan: "Basic plan",
    users: "10"
  },
  {
    title: "invoice #007 - Dec 2022",
    billing_date: "11/12/2022",
    amount: "USD 1000$",
    plan: "Basic plan",
    users: "10"
  },
  {
    title: "invoice #007 - Dec 2022",
    billing_date: "11/12/2022",
    amount: "USD 1000$",
    plan: "Basic plan",
    users: "10"
  },
  {
    title: "invoice #007 - Dec 2022",
    billing_date: "11/12/2022",
    amount: "USD 1000$",
    plan: "Basic plan",
    users: "10"
  }
]

const Table = ({data = []}: any) => {
  const {t} = useTranslation("common")
  return (
      <div className="max-w--xl overflow-auto">
        <table className="shadow-md rounded-md w-full mt-6">
          <thead className="bg-slate-50 border-b border-slate-200">
            <tr>
              <th className="px-6 py-3 text-left text-sm font-medium text-slate-900 rounded-tl-md w-[420px]">
                <div className="flex items-center"><input className="mr-2" type="checkbox"/> Imvoice</div>
              </th>
              <th className="px-6 py-3 text-left text-sm font-medium text-slate-900 rounded-tl-md flex items-center">
                <span className="mr-1">{t("SUBSCRIPTION.BILLING_DATE")}</span> <ArrowDown />
              </th>
              <th className="px-6 py-3 text-left text-sm font-medium text-slate-900">
                {t("SUBSCRIPTION.AMOUNT")}
              </th>
              <th className="px-6 py-3 text-left text-sm font-medium text-slate-900">
                {t("SUBSCRIPTION.PLAN")}
              </th>
              <th className="px-6 py-3 text-left text-sm font-medium text-slate-900">
                {t("SUBSCRIPTION.USERS")}
              </th>
              <th className="px-6 py-3 text-left text-sm font-medium text-slate-900">
                
              </th>
            </tr>
          </thead>
          <tbody>
            {
              ( datatemp).map((el: any) => {
                return (
                  <tr key={el._id} className="">
                    <td className="px-6 py-4 text-sm font-medium text-slate-900 truncate text-ellipsis max-w-[420px] flex items-center">
                      <input className="mr-2" type="checkbox"/> 
                      <DocumentTextIcon width={28} height={28} color="#747171"/>
                      <span className="ml-1">{el.title} </span>
                      <div className="flex items-center ml-2 px-2 py-1 rounded-full border ">
                        <CheckMark width="14" height="10"/> 
                        <span className="ml-1">Paid</span>
                      </div>
                    </td>
                    <td className="px-6 py-4 text-sm font-medium text-slate-900 truncate text-ellipsis max-w-[380px]">
                      {moment(el.billing_date).format('llll')}
                    </td>
                    <td className="px-6 py-4 text-sm font-medium text-slate-900 truncate text-ellipsis capitalize">
                      {el?.amount}
                    </td>
                    <td className="px-6 py-4 text-sm text-slate-900">
                      {el.plan}
                    </td>
                    <td className="px-6 py-4 text-sm text-slate-900">
                      {el?.user} {t("SUBSCRIPTION.USERS")}
                    </td>
                    <td>
                      <div className="border border-[#f4f4f4] rounded-lg px-2 py-1 flex items-center w-fit"><FolderArrowDownIcon color="#484646" width={24} height={24} /> <span className=" text-xs mr-1">{t("SUBSCRIPTION.DOWNLOAD")}</span></div>
                    </td>

                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
  );
};

export default Table;
