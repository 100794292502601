import React, { useEffect, useState } from 'react';
import { client, setToken } from 'api/client';
import { Endpoint, END_POINT_V2 } from 'api/endpoint';
import { useAlert } from "react-alert";
type AuthProps = {
  isAuthenticated: boolean;
  authenticate: Function;
  signOut: Function;
  verifyOTPSuccess: Function,
  profile: any;
};
export const AuthContext = React.createContext({} as AuthProps);

const AuthProvider = (props: any) => {
  const [token, setAuthToken] = useState( localStorage.getItem('epis-token'));
  const [isAuthenticated, makeAuthenticated] = useState(token ? true : false);
  const [profile, setProfile] = useState({});
  const alert = useAlert();
  useEffect(() => {
    if (token) {
      client
        .get(`${END_POINT_V2.PROFILE}`)
        .then((result) => {
          if (result.data?.errorCode === '00') {
            setProfile(result.data.data)
            makeAuthenticated(true)
          }
          else {
            alert.error(result?.data?.message)
            localStorage.removeItem('epis-token');
            makeAuthenticated(false)
          }
        })
        .catch((error) => {
          alert.error(error?.response?.data?.message)
          localStorage.removeItem('epis-token');
          makeAuthenticated(false)
        });
    }
  }, [token]);

  async function authenticate({ phone, password }, setErrorRequestMsg, setSubmitting, setLoginInfo, setOpenModalVerifyCode) {
    await client
      .post(`${END_POINT_V2.LOGIN}`, { phone, password })
      .then((result) => {
        if (result.status === 200) {
          setLoginInfo(result.data?.data)
          if (result.data?.data?.status === 'inactive')
            setOpenModalVerifyCode(true)
          else {
            localStorage.setItem('epis-token', result.data?.data?.token);
            setToken(result.data?.data?.token);
            setAuthToken(result.data?.data?.token);
          }
        }
      })
      .catch((error) => {
        if (error.response && error.response.data.message) {
          setErrorRequestMsg(error.response.data.message)
          setSubmitting(false)
        }
      });
  }

  function signOut(cb) {
    localStorage.removeItem('epis-token');
    makeAuthenticated(false)
    setTimeout(cb, 100);
  }

  async function verifyOTPSuccess({ token }) {
    localStorage.setItem('epis-token', token);
    setToken(token)
    makeAuthenticated(true)
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        authenticate,
        verifyOTPSuccess,
        signOut,
        profile,
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
