import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { BaseProvider } from 'baseui';
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';
import { theme } from './theme';
import Routes from './routes';
import * as serviceWorker from './serviceWorker';
import './theme/global.css';
import AlertTemplate from 'react-alert-template-basic'
import { positions, Provider } from 'react-alert';
import { ToasterContainer } from 'baseui/toast';
import {I18nextProvider} from 'react-i18next';
import i18next from 'i18next';
//@ts-ignore
import common_en from "./translations/en/common.json";
//@ts-ignore
import common_vi from "./translations/vi/common.json";


i18next.init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: 'en',                              // language to use
    resources: {
        en: {
            common: common_en               // 'common' is our custom namespace
        },
        vi: {
          common: common_vi               // 'common' is our custom namespace
      },
    },
});


const options = {
  timeout: 5000,
  position: positions.TOP_RIGHT
};


const client = new ApolloClient({
  uri: process.env.REACT_APP_API_URL,
  cache: new InMemoryCache(),
});

function App() {
  const engine = new Styletron();

  return (
    <I18nextProvider i18n={i18next}>
      <ToasterContainer>
        <ApolloProvider client={client as any}>
          <StyletronProvider value={engine}>
            <BaseProvider theme={theme}>
              <Provider template={AlertTemplate} {...options}>
                <BrowserRouter>
                  <Routes />
                </BrowserRouter>
              </Provider>
            </BaseProvider>
          </StyletronProvider>
        </ApolloProvider>
      </ToasterContainer>
    </I18nextProvider>
    
  );
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
