import { styled } from "baseui";
export const Wrapper = styled("div", ({ $theme }) => ({
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: '20px',
  backgroundColor: $theme.colors.borderF1,

  "@media only screen and (max-width: 520px)": {
    backgroundColor: "#fff"
  }
}));

export const FormWrapper = styled("div", () => ({
  width: "470px",
  borderRadius: "3px",
  backgroundColor: "#ffffff",
  padding: "30px 20px",
  height: '100%',
  display: "flex",
  flexDirection: "column",

  "@media only screen and (max-width: 500px)": {
    width: "100%",
    padding: "30px"
  }
}));

export const LogoWrapper = styled("div", () => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "20px"
}));

export const LogoImage = styled("img", () => ({
  display: "block",
  backfaceVisibility: "hidden",
  maxWidth: "150px"
}));

export const RegisterWrapper = styled("div", () => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: "20px"
}))

export const RegisterText = styled("span", ({ $theme }) => ({
  ...$theme.typography.font12,
  color: $theme.colors.textNormal,
  display: "block",
}));

export const ForgotPasswordWrapper = styled("div", () => ({
  textAlign: "end",
  marginBottom: "10px",
}))

export const ForgotPasswordText = styled("span", ({ $theme }) => ({
  ...$theme.typography.font13,
  color: $theme.colors.textNormal,
  display: "block",
  ':hover': {
    cursor: "pointer",
  },
}));
export const BackIconWrapper = styled("div", ({ $theme }) => ({
  marginBottom: "10px",
  ':hover': {
    cursor: "pointer",
  },
}));
