import React from 'react';
export const CaretDownIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='10'
      height='5'
      viewBox='0 0 10 5'
    >
      <path
        data-name='_ionicons_svg_md-arrow-dropdown (2)'
        d='M128,192l5,5,5-5Z'
        transform='translate(-128 -192)'
        fill='currentColor'
      />
    </svg>
  );
};
