import React, { useEffect, useState } from 'react'
import { Controller, useForm } from "react-hook-form";
import Select, { StylesConfig } from "react-select";
import { Switch } from '@headlessui/react'
import { ErrorMessage } from '@hookform/error-message';
import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { END_POINT_V2 } from 'api/endpoint';
import { useAlert } from 'react-alert'
import { useDebouncedCallback } from 'use-debounce/lib';
import { client } from 'api/client';

const customSelectStyle: StylesConfig<any, true> = {
  clearIndicator: (base) => ({ ...base, display: 'none' }),
  indicatorSeparator: (base) => ({ ...base, display: 'none' }),
  dropdownIndicator: (base) => ({ ...base, padding: 4, color: "#2E3A59", marginRight: 12 }),
  control: (styles) => ({
    ...styles,
    borderColor: '#EBEBF0',
    minHeight: '40px',
    justifyContent: 'space-between',
    width: '100%',
    borderRadius: '8px',
    fontSize: 12
  }),
  multiValue: (provided) => ({
    ...provided,
    background: '#F2F9FF',
    borderRadius: '18px',
    textColor: '#2E3A59',
    fontWeight: 400,
  }),
}

export function VNDForm({
  selectedRegion
}) {
  const {
    watch,
    setValue,
    control,
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();
  const { t } = useTranslation("common")
  const [banks, setBanks] = useState<any>([])
  const alert = useAlert()
  const [accountInfo, setAccountInfo] = useState<any>(null)
  // const []

  const numberAccountWatch = watch('number')
  const bankWatch = watch('bank')

  const onSubmit = (val: any) => {
    withdraw(val)
  }

  const getBanks = async () => {
    try {
      const res = await client.get(`${END_POINT_V2.GET_BANKS}`)
      setBanks(res.data?.data)
    } catch (error) {
      console.log(error)
    }
  }

  const withdraw = async (values) => {
    client.post(`${END_POINT_V2.TRANSFER_MONEY}`, {
      bankCode: bankWatch?.code,
      "content": values.content,
      "accountNo": numberAccountWatch,
      "accountName": values.name,
      "otp": "123123",
      "amount": values.amount,
      "type": selectedRegion?.code,
    })
  }

  const getUserAccount = () => {
    console.log(numberAccountWatch)
    if(!bankWatch || !numberAccountWatch || numberAccountWatch === '') return

    axios.post(`${END_POINT_V2.LOOKUP_USER_BANK}`,{
      accountNumber: numberAccountWatch,
      bin: bankWatch?.bin
    })
      .then(res => {
        setAccountInfo(res.data?.data)
        if(res.data?.data?.code !== '00'){
          return alert.error(t("FINANCE.ACCOUNT_NOT_FOUND"))
        }
        setValue('name', res.data?.data?.data?.accountName)
      })
      .catch(err => {
        alert.error(t("FINANCE.ACCOUNT_NOT_FOUND"))
      })
  }

  useEffect(()=>{
    getBanks()
  },[])

  return (
    <form className=' w-full ' onSubmit={handleSubmit(onSubmit)}>
      <div >
        <label className=' text-xs' htmlFor="amount">{t("FINANCE.AMOUNT")}</label>
        <input
          className="border mt-1 text-xs py-2 px-2 rounded-md w-full focus:outline-none"
          type="number"
          id="amount"
          name="amount"
          placeholder={t("FINANCE.ENTER_AMOUNT")}
          ref={register({ required: true })}
        />
        {errors.amount && <p
          className="text-red-500 text-xs"
        >
          {t("FINANCE.REQUIRED")}
        </p>}
      </div>

      <div className='mt-4 flex items-start justify-between'>
        <div>
          <label className=' text-xs' htmlFor="number">{t("FINANCE.ACCOUNT")}</label>
          <div className=' ml-4 mt-2'>
            <div className='text-white px-3 py-2 rounded-md bg-[#00c58d] text-xs'> {t("FINANCE.BANK")}</div>
          </div>
        </div>
        <div className=' text-[#00c58d] text-xs mt-2 cursor-pointer'>{t("FINANCE.SAVED_LIST")} </div>
      </div>

      <div className="py-2 w-full items-end ">
        <label className=' text-xs mr-3' htmlFor="bank">{t("FINANCE.CHOOSE_A_BANK")}</label>
        {/* @ts-ignore */}
        <Controller
          control={control}
          name="bank"
          //@ts-ignore
          {...register('bank', {
            required: {
              value: true,
              message: `${t("FINANCE.REQUIRED")}`
            },
          })}
          render={(field) => {
            return (
              <Select
                className=" font-normal text-xs"
                styles={customSelectStyle}
                hideSelectedOptions={false}
                value={field.value || []}
                options={banks}
                closeMenuOnSelect={true}
                menuPlacement="auto"
                placeholder={t("FINANCE.CHOOSE_A_BANK")}
                maxMenuHeight={200}
                onChange={(val) => {
                  field.onChange(val)}}
                getOptionLabel={(option) => `${option.name} - ${option.short_name}`}
                getOptionValue={(option) => option.id}
              />
            )
          }}
        />
        <ErrorMessage
          className="text-red-500 text-xs"
          errors={errors}
          name="bank"
          as="p"
        />
      </div>

      <div className='mt-4'>
        <label className=' text-xs' htmlFor="number">{t("FINANCE.ACCOUNT_NUMBER")}</label>
        <input
          className="border mt-1 text-xs py-2 px-2 rounded-md w-full focus:outline-none"
          type="text"
          id="number"
          name="number"
          onBlur={()=>{
            getUserAccount()
          }}
          placeholder={t("FINANCE.ENTER_ACCOUNT_NUMBER")}
          ref={register({ required: true })}
        />
        {errors.number && <p
          className="text-red-500 text-xs"
        >
          {t("FINANCE.REQUIRED")}
        </p>}
      </div>

      <div className=''>
        <label className=' text-xs' htmlFor="name">{t("FINANCE.ACCOUNT_NAME")}</label>
        <input
          className="border mt-1 text-xs py-2 px-2 rounded-md w-full focus:outline-none"
          type="text"
          id="name"
          name="name"
          // disabled
          readOnly
          placeholder={t("FINANCE.ENTER_ACCOUNT_NAME")}
          ref={register({ required: true })}
        />
        {errors.name && <p
          className="text-red-500 text-xs"
        >
          {t("FINANCE.REQUIRED")}
        </p>}
      </div>

      <div className="py-2 flex w-full justify-end">
        <label className=' text-xs mr-3' htmlFor="remember">{t("FINANCE.REMEMBER")}</label>
        {/* @ts-ignore */}
        <Controller
          control={control}
          name="remember"
          render={(field) => {
            return (
              <Switch
                checked={getValues("remember")}
                name="remember"
                onChange={(val) => field.onChange(val)}
                className={`${getValues("remember") ? 'bg-teal-900' : 'bg-teal-700'}
                  relative inline-flex h-[18px] w-[36px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
              >
                <span
                  aria-hidden="true"
                  className={`-translate-y-1 shadow-sm bg-[#f5f5f5] ${getValues("remember") ? 'translate-x-4' : '-translate-x-1'}
                  pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                />
              </Switch>
            )
          }}
        />
      </div>

      <div className=''>
        <label className=' text-xs' htmlFor="content">{t("FINANCE.CONTENT")}</label>
        <textarea
          className="border mt-1 text-xs py-2 px-2 rounded-md w-full focus:outline-none"
          id="content"
          name="content"
          placeholder={t("FINANCE.ENTER_CHOOSE_A_BANK")}
          ref={register({ required: true })}
        />
        {errors.content && <p
          className="text-red-500 text-xs"
        >
          {t("FINANCE.REQUIRED")}
        </p>}
      </div>
      <div className=' w-full flex justify-center mt-6'>
        <Button type="submit">{t("FINANCE.SUBMIT")}</Button>
      </div>
    </form>
  )
}


export default function Domestic() {
  const { t } = useTranslation("common")
  const [regions, setRegions] = useState([])
  const [selectedRegion, setSelectedRegion] = useState([])
  const alert = useAlert()

  useEffect(() => {
    getRegions()
  }, [])

  const getRegions = async () => {
    const res = await axios.get(`${END_POINT_V2.GET_REGIONS}`)
    console.log(res.data.data)
    setRegions(res.data?.data)
  }

  const renderForm = () => {
    if (selectedRegion[0]) {
      switch (selectedRegion[0].code) {
        case 'VI':
          return <VNDForm selectedRegion={selectedRegion[0]} />
          break;

        default:
          break;
      }
    }
  }


  return (<>
    <Select
      className=" font-normal text-xs"
      styles={customSelectStyle}
      hideSelectedOptions={false}
      value={selectedRegion}
      options={regions}
      closeMenuOnSelect={true}
      menuPlacement="auto"
      placeholder={t("GENERAL.CHOOSE_REGION")}
      maxMenuHeight={200}
      isOptionDisabled={(option) => +option.status !== 1}
      getOptionLabel={(option) => {
        let str = ``
        if(+option.status === 2) {
          str = `(${t("GENERAL.COMING_SOON")})`
        }
        if(+option.status === 3) {
          str = `(${t("GENERAL.DISABLED")})`
        }
        return`${option.name} ${str}`
      }}
      getOptionValue={(option) => option.id}
      onChange={(e: any) => {
        switch (+e?.status) {
          case 1:
            setSelectedRegion([e])
            break;
          case 2:
            alert.error(t("GENERAL.COMING_SOON"))
            break;

          case 3:
            alert.error(t("GENERAL.DISABLED"))
            break;

          default:
            break;
        }

        // console.log(e)
      }}
    />
   {
    renderForm()
   }
  </>
  )
}