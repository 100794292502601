import { Plus } from 'assets/icons/Plus'
import React from 'react'
import { useTranslation } from 'react-i18next'
import BasicPlan from './BasicPlan'
import BillingHistory from './BillingHistory'
import PaymentMethod from './PaymentMethod'

export default function Subscription() {
  const {t} = useTranslation("common")
  return (
    <div className="rounded-3xl bg-[#f4f4f4]">
      <div className="container mx-auto py-10 px-4 lg:px-0">
        <div>
          <h2 className=' text-2xl font-bold'>
            {t("SUBSCRIPTION.PLAN_AND_BILL")}
          </h2>
          <p>{t("SUBSCRIPTION.PLAN_AND_BILL_CONTENT")}</p>
        </div>

        <div className='grid grid-cols-2 gap-6 mt-10'>
          <div className=' col-span-2 lg:col-span-1 rounded-lg shadow-sm border border-[#f5f5f5] bg-white'>
            <BasicPlan />
          </div>

          <div className=' col-span-2 lg:col-span-1 rounded-lg shadow-sm border border-[#f5f5f5] bg-white p-6'>
            <PaymentMethod />
          </div>
        </div>
         
        <div>
          <BillingHistory />
        </div>

      </div>
    </div>
  )
}
