import { ArrowDown } from 'assets/icons/ArrowDown'
import { t } from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function International() {
  const {t} = useTranslation("common")
  return (
    <div className="grid grid-cols-12 gap-2 px-8">
      <div className="col-span-12 ">
        <div className="font-bold text-xl">{t("FINANCE.SEND_MONEY")}</div>
      </div>

      <div className="col-span-12 m-auto w-full bg-white shadow-md h-32 rounded-xl p-4 flex justify-between mt-10 items-center px-10">
        <div className=" text-slate-400 font-bold">{t("FINANCE.FROM")}</div>
        <div >
          <select className="bg-transparent text-black font-bold outline-none">
            <option>Việt Nam</option>
            <option>Australia</option>
          </select>
        </div>
      </div>

      <div className="col-span-12 bg-white shadow-md h-32  rounded-xl p-4 mt-5 px-10">
        <div className=" text-slate-400 font-bold">{t("FINANCE.SENDER")}</div>
        <div className="mt-5">
          <div className="font-bold text-xl">424,123.00 <span className="text-sm font-semibold">CAD</span></div>
        </div>
      </div>

      <div className="col-span-12 text-xs text-[#333333] mt-2 ml-2">
        Rate = 107.30 Jul 9
      </div>

      <div className="col-span-12 mt-8">
        <div className=" p-2 w-10 h-10 rounded border rounded-full m-auto flex justify-center items-center">
          <ArrowDown/>
        </div>
      </div>

      <div className="col-span-12 bg-white shadow-md rounded-xl p-4 h-32  flex justify-between mt-10 items-center px-10">
        <div className=" text-slate-400 font-bold">{t("FINANCE.TO")}</div>
        <div >
          <select className="bg-transparent text-black font-bold outline-none">
            <option>Việt Nam</option>
            <option>Australia</option>
          </select>
        </div>
      </div>

      <div className="col-span-12 bg-white shadow-md h-32  rounded-xl p-4 mt-5 px-10">
        <div className=" text-slate-400 font-bold">{t("FINANCE.RECEIVER")}</div>
        <div className="mt-4">
          <div className="font-bold text-xl">424,123.00 <span className="text-sm font-semibold">CAD</span></div>
        </div>
      </div>
    </div>
  )
}
