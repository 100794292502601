
export const countryCodeSelectOptions = [
  { value: "+84", label: "+84 (VN)", countryCode: "VN", code: "84" },
  { value: "+61", label: "+61 (AUD)", countryCode: "AU", code: "61" },
];

export const AUState = [
  {
    "id": "New South Wales",
    "name": "New South Wales"
  },
  {
    "id": "Queensland",
    "name": "Queensland"
  },
  {
    "id": "South Australia",
    "name": "South Australia"
  },
  {
    "id": "Tasmania",
    "name": "Tasmania"
  },
  {
    "id": "Victoria",
    "name": "Victoria"
  },
  {
    "id": "Western Australia",
    "name": "Western Australia"
  },
  {
    "id": "Australian Capital Territory",
    "name": "Australian Capital Territory"
  },
  {
    "id": "Northern Territory",
    "name": "Northern Territory"

  }
]