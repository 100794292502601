import { useTranslation } from "react-i18next";


const BankCard = ({ card }) => {
    const { t } = useTranslation("common")

    const showLogo = () => {
        switch (card?.currency) {
            case 'VND':
                return "assets/flags/VN.png"

            case 'AUD':
                return "assets/flags/AU.png"

            default:
                break;
        }
    }

    const showBank = () => {
        switch (card?.currency) {
            case 'VND':
                return 'VPB'

            case 'AUD':
                return 'AUB'

            default:
                break;
        }
    }

    return (
        <div className="bg-white flex justify-center items-center my-6">
            <div className="space-y-4">
                <div className="w-80 h-48 m-auto bg-red-100 rounded-xl relative text-white shadow-2xl transition-transform transform hover:scale-105">
                    <img className="relative object-cover w-full h-full rounded-xl " src="https://i.imgur.com/kGkSg1v.png" alt="" />
                    <div className="w-full px-8 absolute top-8">
                        <div className="flex justify-between">
                            <div className="">
                            <p className="font-light">
                                {t("FINANCE.CARD_NUMBER")}
                            </p>
                            <p className="truncate font-medium tracking-more-wider">
                                {card.accountNumber}
                            </p>
                                
                            </div>
                            <img className="w-16 h-12 rounded-md" src={showLogo()} alt="" />
                        </div>
                        <div className="pt-1">
                        <p className="font-light">
                                    {t("FINANCE.NAME")}
                                </p>
                                <p className="truncate font-medium tracking-widest">
                                    {card.nickName}
                                </p>
                        </div>

                        <div className="pt-1">
                            <p className="font-light">
                                {t("FINANCE.BANK")}
                            </p>
                            <p className="font-medium tracking-more-wider">
                                {showBank()}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default BankCard;